import React, { useState, useEffect, useLayoutEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import queryString from "query-string";

import { MainNav, Footer } from "../components";
import ProductItem from "../components/ProductItem";
import Slider from "../components/Slider";
import Filters from "../components/Products/Filters";

import { getProductsAsync } from "../actions/productosActions";
import {
	filterByCategory,
	filterBySearch,
	filterGeneric,
	filterManufacturer,
	filterBySubcategory,
} from "../utils/filters";
import Pagination from "../components/Products/Pagination";
import { getCategories } from "../actions/categoriasActions";

const Products = () => {
	const { search } = useLocation();
	const { push } = useHistory();
	const [products, setProducts] = useState([]);
	const [currentProducts, setCurrentProducts] = useState([]);
	const [categories, setCategories] = useState([]);
	const [selectedFilter, setSelectedFilter] = useState("none");
	const [selectedCategory, setSelectedCategory] = useState({
		name: "Todas",
		subcategories: [],
	});
	const [selectedSubcategory, setSelectedSubcategory] = useState({ name: "" });

	const filters = queryString.parse(search);

	const getProductsAndCategories = async () => {
		const data = await getProductsAsync();

		const res = await getCategories();

		if (res) setCategories(res);

		const categoryId = parseInt(filters.category);
		const subcategoryId = parseInt(filters.subcategory);

		if (res && categoryId && !subcategoryId) {
			categoryClicked(
				{ preventDefault: () => {} },
				res.find((category) => category.id == categoryId)
			);
			setSelectedFilter("category");
		} else if (subcategoryId) {
			subcategoryClicked({ preventDefault: () => {} }, subcategoryId, res);
			setSelectedFilter("category");
		}

		setProducts(data);
		setCurrentProducts(data);
	};

	const filterAll = async () => {
		if (filters.subcategory) {
			return setCurrentProducts(
				filterBySubcategory(products, filters.subcategory)
			);
		}
		if (filters.category) {
			return setCurrentProducts(filterByCategory(products, filters.category));
		}
		if (filters.q) {
			return setCurrentProducts(filterBySearch(products, filters.q));
		}
		if (selectedFilter === "generic") {
			return setCurrentProducts(filterGeneric(products));
		}
		if (selectedFilter === "manufacturer") {
			return setCurrentProducts(filterManufacturer(products));
		}

		return setCurrentProducts(await getProductsAsync());
	};

	useEffect(() => {
		filterAll();
	}, [categories, products, search, selectedFilter]);

	useEffect(() => {
		getProductsAndCategories();
	}, []);

	const categoryClicked = (e, category = {}) => {
		e.preventDefault();
		setSelectedCategory(category);
		setSelectedSubcategory({ name: "" });
		push(`/products?category=${category.id}`);
	};

	const subcategoryClicked = (e, subcategoryId, categoryList) => {
		e.preventDefault();
		const category = categoryList.find((category) =>
			category.subcategories.find(
				(subcategory) => subcategory.id == subcategoryId
			)
		);

		const query = {
			category: category ? category.id : "",
			subcategory: subcategoryId,
		};

		if (category) {
			setSelectedCategory(category);
			setSelectedSubcategory(
				category.subcategories.find(
					(subcategory) => subcategory.id == subcategoryId
				)
			);

			push(`/products?${queryString.stringify(query)}`);
		}
	};

	return (
		<>
			<MainNav query={queryString.parse(search).q} />

			<div className="max-w-screen-2xl mx-auto flex flex-col lg:flex-row px-20 pt-5 lg:pt-16 pb-20">
				<Filters
					categoryClicked={categoryClicked}
					categories={categories}
					setFilter={setSelectedFilter}
					currentFilter={selectedFilter}
				/>
				<div className="w-full lg:w-4/5 space-y-8">
					{selectedFilter === "none" && (
						<p>
							<b>Pro Tip: </b>Usa un filtro a la izquierda para limitar la
							búsqueda
						</p>
					)}
					{selectedFilter === "generic" && (
						<div className="bg-add md:mr-8 lg:mr-20 xl:mr-3 2xl:mr-10">
							<h2 className="text-4xl px-4 py-2 font-bold">Genéricos</h2>
						</div>
					)}
					{selectedFilter === "category" && (
						<>
							<div className="pl-8 lg:pl-2">
								<div className="container items-center">
									<div className="w-full">
										<p className="text-black text-xl sm:text-2xl font-normal">
											Inicio / Catálogo / {selectedCategory?.name} /{" "}
											{selectedSubcategory?.name}
										</p>
									</div>
								</div>
							</div>
							<div className="bg-add md:mr-8 lg:mr-20 xl:mr-3 2xl:mr-10">
								<h2 className="text-4xl px-4 py-2 font-bold">
									{selectedCategory.name}{" "}
									{!!selectedSubcategory?.name &&
										`- ${selectedSubcategory.name}`}
								</h2>
							</div>
							{!!selectedCategory.subcategories.length && (
								<Slider
									categories={categories}
									data={selectedCategory.subcategories}
									itemClick={subcategoryClicked}
								/>
							)}
						</>
					)}
					{selectedFilter === "manufacturer" && (
						<div className="bg-add border md:mr-8 lg:mr-20 xl:mr-3 2xl:mr-10">
							<h2 className="text-4xl max-w-full block overflow-hidden text-ellipsis border py-2 font-bold">
								Fabricantes
							</h2>
						</div>
					)}
					<div className="grid md:grid-cols-2 xl:grid-cols-4 gap-4 w-full p-0 justify-evenly xl:justify-around">
						{currentProducts?.length ? (
							currentProducts.map((product) => (
								<ProductItem data={product} key={product.id} />
							))
						) : (
							<h1>No hay productos usando estos argumentos</h1>
						)}
					</div>
				</div>
			</div>
			{products > 16 && <Pagination />}
			<Footer />
		</>
	);
};

export default Products;
